import React from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"

import { maxWidth } from "./maxwidth"
import { colors } from "./colors"

const useStyles = makeStyles(() => ({
  footer: {
    "& img, .gatsby-image-wrapper": {
      width: "100%",
      height: "100%",
    },

    backgroundColor: colors.azul,
    color: "white",

    "& a": {
      textDecoration: "none",
      color: "#fff",
    },
  },
  spin: {
    animation: "$brain-spin 2.5s linear infinite",
  },
  "@keyframes brain-spin": {
    "0%": {
      transform: "rotate(0)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}))

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      brain: file(relativePath: { eq: "logo_bola.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const classes = useStyles()

  return (
    <Box
      width={1}
      display="flex"
      justifyContent="center"
      className={classes.footer}
    >
      <Box
        width={1}
        maxWidth={maxWidth}
        px={{ xs: "10px", sm: "20px" }}
        className={classes.contato}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "center", sm: "flex-end" }}
        py="15px"
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Typography variant="caption">
          © Todos os Direitos Reservados
        </Typography>
        <Typography variant="caption">
          Vanzete Gomes Filho & Gustavo M de Sá Gomes | Advogados Associados
        </Typography>
        <Box
          display="flex"
          alignItems="flex-end"
          component="a"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.braincomdigital.com.br"
        >
          <Typography variant="caption">Developed by</Typography>
          <Box width="40px" height="40px">
            <GatsbyImage
              className={classes.spin}
              image={data.brain.childImageSharp.gatsbyImageData}
              alt="James Macário"
              objectFit="contain"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export { Footer }
